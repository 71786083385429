import React, { useEffect, useRef } from "react";
import gsap from "gsap";

function DropdownSaveButton({ save, clear }: { save: any; clear: any }) {
  const currentLeftOptionsRef = useRef(null);
  const currentRightOptionsRef = useRef(null);
  const buttonBaseStyles = `relative font-semibold leading-tight overflow-hidden text-base opacity-100 inline-block py-2 duration-200`;

  useEffect(() => {
    if (currentLeftOptionsRef.current && currentRightOptionsRef.current) {
      gsap.killTweensOf([currentLeftOptionsRef.current, currentRightOptionsRef.current]);
      gsap.to(currentLeftOptionsRef.current, {
        opacity: 1,
        x: 0,
        duration: 0.3,
        ease: "power3.out",
        delay: 0,
      });
      gsap.to(currentRightOptionsRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        ease: "power3.out",
        delay: 0.1,
      });
    }
    return () => {
      gsap.killTweensOf([currentLeftOptionsRef.current, currentRightOptionsRef.current]);
    };
  });

  return (
    <div className="bg-white">
      <div className="flex flex-no-wrap justify-between items-center p-4 px-6 md:py-2">
        <div
          ref={currentLeftOptionsRef}
          className="opacity-0"
          style={{
            transform: "translate(-10px, 0)",
          }}
        >
          <button onClick={clear} className={`transition-all text-slate-400 border-white underline hover:text-slate-300`}>
            Clear
          </button>
        </div>
        <div
          ref={currentRightOptionsRef}
          className="opacity-0"
          style={{
            transform: "scale(0.9)",
          }}
        >
          <button
            onClick={save}
            className={`${buttonBaseStyles} px-6 border-2 rounded-full transition-colors text-white border-slate-400 bg-slate-400 hover:border-slate-300 hover:bg-slate-300`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default DropdownSaveButton;
