import React from "react";
import SVG from "react-inlinesvg";

function FilterTitle({ label }: { label?: string }) {
  return (
    <div className="mx-auto px-6 md:mx-0 md:px-0">
      <div className="w-full flex items-center justify-center p-4 px-6 rounded-full bg-white shadow-md mb-4 md:mb-0 md:shadow-none md:w-auto md:bg-transparent">
        <div className="hidden lg:block">
          <SVG src="/images/icons/filter.svg" width="18" height="16" title="" className="mr-4" cacheRequests={true} />
        </div>
        {label && <div className="font-medium text-base md:text-xs lg:text-base">{label}</div>}
      </div>
    </div>
  );
}

export default FilterTitle;
