import React, { useRef, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import gsap from "gsap";
import classnames from "classnames";
import { Contentful_Sector, Contentful_ResearchArea, Contentful_ContentType, Contentful_Department } from "graphql-types";
import { OptionType } from "./index";

type ItemType = OptionType & {
  group: any;
  toggle: any;
  isSecondary: boolean;
  hasChildren: boolean;
  children: any;
};

function DropdownItem({ group, toggle, isSecondary, hasChildren, children, ...item }: ItemType) {
  const currentItemRef = useRef(null);
  const currentItemBackgroundRef = useRef(null);
  const currentArrowDown = useRef(null);
  const currentArrowUp = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (currentItemRef.current) {
      const delay = 0;
      gsap.killTweensOf([currentItemRef.current]);
      gsap.to(currentItemRef.current, { opacity: 1, x: 0, ease: "power3.out", delay: delay });
    }

    if (currentItemBackgroundRef.current) {
      gsap.killTweensOf([currentItemBackgroundRef.current]);
      gsap.to(currentItemBackgroundRef.current, {
        opacity: item.selected ? 1 : 0,
        duration: 0.6,
        ease: "power3.out",
      });
    }

    if (currentArrowDown.current && currentArrowUp.current) {
      gsap.killTweensOf([currentArrowDown.current, currentArrowUp.current]);

      if (isOpen) {
        gsap.to(currentArrowDown.current, { y: 10, opacity: 0, duration: 0.3, ease: "power3.out" });
        gsap.to(currentArrowUp.current, { y: 0, opacity: 1, duration: 0.3, ease: "power3.out" });
      } else {
        gsap.to(currentArrowDown.current, { y: 0, opacity: 1, duration: 0.3, ease: "power3.out" });
        gsap.to(currentArrowUp.current, { y: -10, opacity: 0, duration: 0.3, ease: "power3.out" });
      }
    }

    return () => {
      gsap.killTweensOf([currentItemRef.current, currentItemBackgroundRef.current]);
      gsap.killTweensOf([currentArrowDown.current, currentArrowUp.current]);
    };
  }, [isOpen]);

  return (
    <li className="relative bg-white border-t border-t-slate-400">
      <div ref={currentItemBackgroundRef} className="absolute inset-0 bg-slate-100 opacity-0" />
      <div
        ref={currentItemRef}
        className="relative opacity-0"
        style={{
          transform: "translate(-10px, 0)",
        }}
      >
        <div className={`${isSecondary ? "pl-4" : ""}`}>
          <div className={`font-medium text-base leading-tight text-slate-400`}>
            <div className="flex flex-no-wrap justify-between items-center p-4 px-6">
              <div onClick={() => toggle(item, group)}>
                <label className="relative flex items-center">
                  <div
                    className="relative flex-none rounded-sm mr-3 pt-0 bg-transparent overflow-hidden"
                    style={{ width: 16, height: 16 }}
                  >
                    <div className="absolute inset-0 flex items-center justify-center bg-transparent border border-slate-200" />
                    <div
                      className={classnames(
                        "absolute inset-0 flex items-center justify-center bg-slate-400 border-slate-400 opacity-0",
                        {
                          "opacity-100": item.selected && !item.partial,
                          "opacity-50": item.partial,
                        }
                      )}
                    >
                      <SVG
                        src="/images/icons/active.svg"
                        width="8"
                        height="6"
                        title=""
                        className="absolute"
                        cacheRequests={true}
                      />
                    </div>
                  </div>
                  <div>
                    {
                      (item as Contentful_Sector | Contentful_ResearchArea | Contentful_ResearchArea | Contentful_Department)
                        .label
                    }
                    {(item as Contentful_ContentType).name}
                  </div>
                </label>
              </div>
              {hasChildren && (
                <div>
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <div className="relative pl-4">
                      <div ref={currentArrowDown} className="relative">
                        <SVG
                          src={`/images/icons/arrow-down.svg`}
                          width="14"
                          height="8"
                          title=""
                          className=""
                          cacheRequests={true}
                        />
                      </div>
                      <div ref={currentArrowUp} className="absolute top-0 left-0 ml-4 opacity-0">
                        <SVG
                          src={`/images/icons/arrow-up.svg`}
                          width="14"
                          height="8"
                          title=""
                          className=""
                          cacheRequests={true}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
            {children && isOpen && <ul>{children}</ul>}
          </div>
        </div>
      </div>
    </li>
  );
}

export default DropdownItem;
