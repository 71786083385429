import React, { useEffect, useState } from "react";
import queryString from "query-string";
import classnames from "classnames";
import Merge from "lodash/merge";
import {
  Maybe,
  Contentful_Sector,
  Contentful_ResearchArea,
  Contentful_ContentType,
  Contentful_Department,
} from "graphql-types";
import { navigate } from "gatsby";
import useStateCallback from "../../hooks/useStateCallback";
import { FilterContext } from "./context";
import Dropdown from "./dropdown";
import DropdownItem from "./dropdown-item";
import FilterTitle from "./filter-title";
import FilterClearAll from "./filter-clear-all";

export type OptionType = (Contentful_Sector | Contentful_ResearchArea | Contentful_ContentType | Contentful_Department) & {
  selected: boolean;
  partial: boolean;
  name: string;
};

export type GroupType = {
  groupLabel: string;
  ref: string;
  items: Items;
};
type Items = Array<Maybe<OptionType>>;

const rebuild = (state: GroupType[], type: string, payload: string, singular: boolean) => {
  const selected = type === "select" ? true : false;

  return state.map((group) => {
    group.items.map((item: any) => {
      // if (!item) return null;

      // This is a new property. Add if missing.
      if (typeof item.selected === undefined) item.selected = false;
      if (typeof item.partial === undefined) item.partial = false;

      const items =
        (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];

      // If we have a 'clear' action, reset that group.
      if (type === "clear" && payload === group.ref) {
        item.selected = false;
        item.partial = false;

        (items as any[]).map((child: any) => {
          child.selected = false;
          return child;
        });

        return item;
      }

      // Parent level
      if (item.sys.id === payload) {
        item.selected = selected;

        (items as any[]).map((child: any) => {
          if (typeof child.selected === undefined) child.selected = false;
          if (!singular) child.selected = selected;
          return child;
        });
      }

      // Children
      (items as any[]).map((child: any) => {
        if (child.sys.id === payload) {
          child.selected = selected;
        }
        return child;
      });

      // When children change, check if parent needs to be set to partial
      const childrenSelected = (items as any[]).reduce(
        (accumulator: any, currentValue: any) => (currentValue.selected === true ? ++accumulator : accumulator),
        0
      );

      if (childrenSelected && childrenSelected >= items.length) {
        item.selected = true;
      } else {
        if (items.length) {
          item.selected = false;
        }
      }

      item.partial = childrenSelected >= items.length ? false : childrenSelected > 0 ? true : false;

      return item;
    });

    return group;
  });
};

function Filter({ groups, children }: { groups: GroupType[]; children: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltersEnabled, setIsFiltersEnabled] = useState(false);
  const [state, setState] = useStateCallback(groups);
  const isSSR = typeof document === "undefined";

  const toggle = function (item: any, group: any) {
    let newValue = [...state];
    if (item.selected) newValue = rebuild(newValue, "deselect", item.sys.id, false);
    else newValue = rebuild(newValue, "select", item.sys.id, false);

    setState([...newValue], () => {
      SetQueryUrl();
    });
  };

  const clear = function (group: any) {
    let newValue = [...state];
    newValue = rebuild(newValue, "clear", group.ref, false);
    setState([...newValue], () => {
      SetQueryUrl();
    });
  };

  const clearAll = function () {
    let stateArr: GroupType[] = [...state];
    stateArr.map((group) => {
      (group.items as Items).map((item) => {
        if (!item) return;

        const items =
          (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];

        item.selected = false;
        item.partial = false;

        (items as any[]).map((child: any) => {
          child.selected = false;
          return child;
        });
        return item;
      });
      return group;
    });

    setState([...stateArr], () => {
      // console.log("clear complete", stateArr);
    });
  };

  function setHistory(query: string) {
    const currentQuery = location.search;
    const currentURL = location.href.replace(`${currentQuery}`, "");
    const filterQuery = `${currentURL}${query}`;

    history.pushState({}, document.title, filterQuery);
  }

  function SetQueryUrl() {
    let searchURLParams = new URLSearchParams("");

    // Attach q if exists
    const params = queryString.parse(location.search);
    const q = params.q && (typeof params.q === "object" ? params.q[0] : params.q);
    if (q) searchURLParams.append("q", q);

    state.map((group: GroupType) => {
      const ids: string[] = [];
      group.items.map((item) => {
        if (item?.selected || item?.partial) ids.push(item.sys.id);
        const children =
          (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];
        (children as []).map((child: OptionType) => {
          if (child.selected) ids.push(child.sys.id);
        });
      });
      ids.forEach((id) => {
        searchURLParams.append(group.ref, id);
      });
    });
    if (searchURLParams.toString()) setHistory(`?${searchURLParams.toString()}`);
    else setHistory(``);
  }

  function setFilterFromURL() {
    let searchURLParams = new URLSearchParams(!isSSR ? document.location.search : "");
    const ids = [
      ...searchURLParams.getAll("contentType"),
      ...searchURLParams.getAll("departments"),
      ...searchURLParams.getAll("researchAreasCollection"),
      ...searchURLParams.getAll("sectorsCollection"),
    ];

    if (!ids.length) return;

    let stateArr: GroupType[] = [...state];
    stateArr.map((group) => {
      // Parent items of each group
      (group.items as Items).map((item) => {
        if (!item) return;
        let selectedChildrenCount = 0;
        const items =
          (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];

        // Child items
        (items as any[]).map((child) => {
          if (ids.includes(child.sys.id)) {
            child.selected = true;
            selectedChildrenCount++;
          }
          return child;
        });

        if (ids.includes(item.sys.id)) item.selected = true;
        if (selectedChildrenCount > 0 && selectedChildrenCount < items.length) item.partial = true;
        return item;
      });

      return group;
    });

    setState([...stateArr], () => {
      // console.log("setFilterFromURL complete");
    });
  }

  function buttonClearAll_CLICK() {
    clearAll();
    const newValue = groups;
    setState([...newValue], () => {
      SetQueryUrl();
    });
  }

  useEffect(() => {
    clearAll();

    const filterFromUrl = setTimeout(function () {
      setFilterFromURL();
    }, 500);

    return () => {
      clearTimeout(filterFromUrl);
      clearAll();
    };
  }, []);

  useEffect(() => {
    const selectedFilters = [...state].filter((group: GroupType) => {
      return group.items.filter((item: any) => {
        const childrenSelectedItems: any = item.childrenCollection?.items.filter((item: any) => item.selected);
        return childrenSelectedItems ? childrenSelectedItems.length || item?.selected : item?.selected;
      }).length;
    });

    if (selectedFilters.length) {
      setIsFiltersEnabled(true);
    } else {
      setIsFiltersEnabled(false);
    }

    return () => {};
  }, [state]);

  if (!isSSR) {
    useEffect(() => {
      if (!document.location.search) {
        clearAll();
      } else {
        clearAll();
        setFilterFromURL();
      }
    }, [document.location.search]);
  }

  if (!state.length) return null;

  return (
    <FilterContext.Provider value={state}>
      <div>
        <div className="mb-8 lg:mb-10 relative z-30 ">
          <div className="md:container -mx-6 md:mx-auto">
            <div className="md:flex md:flex-no-wrap md:justify-end">
              <div onClick={() => setIsOpen(!isOpen)}>
                <FilterTitle label={"Filter Selection"} />
              </div>
              <div className={`${isOpen ? "block" : "hidden"} md:flex md:flex-no-wrap md:justify-end`}>
                {state.length > 0 &&
                  state.map((group: GroupType, key: number) => (
                    <Dropdown group={group} key={key} clear={clear}>
                      {group.items.map((item, key) => {
                        if (!item) return null;
                        const children =
                          (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection
                            ?.items ?? [];

                        return (
                          <DropdownItem
                            {...item}
                            isSecondary={false}
                            group={group}
                            toggle={toggle}
                            hasChildren={children.length > 0}
                            key={key}
                          >
                            {(children as any[]).map((child: any, k: any) => (
                              <DropdownItem
                                {...child}
                                isSecondary={true}
                                group={group}
                                toggle={toggle}
                                hasChildren={false}
                                key={k}
                              />
                            ))}
                          </DropdownItem>
                        );
                      })}
                    </Dropdown>
                  ))}

                <div
                  onClick={buttonClearAll_CLICK}
                  className={classnames("no-highlight outline-none opacity-25 pointer-events-none", {
                    "opacity-100 pointer-events-auto": isFiltersEnabled,
                  })}
                >
                  <FilterClearAll label={"Clear all"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </FilterContext.Provider>
  );
}

export default Filter;
