import React from "react";
import SVG from "react-inlinesvg";

function FilterClearAll({ label }: { label?: string }) {
  return (
    <div className="mx-auto px-6 md:mx-0 md:px-0 border-t-2 md:border-t-0 h-full cursor-pointer">
      <div className="w-full flex items-center justify-end p-4 px-0 bg-transparent mb-4 md:mb-0 md:w-auto md:px-6 h-full">
        {/* <div className="block opacity-75">
          <SVG src="/images/icons/no-filter.svg" width="25" height="25" title="" className="mr-2" cacheRequests={true} />
        </div> */}
        {label && <div className="font-medium text-sm md:text-xs">{label}</div>}
      </div>
    </div>
  );
}

export default FilterClearAll;
