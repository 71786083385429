import { useContext } from "react";
import get from "lodash/get";
import {
  Contentful_DepartmentCollection,
  Contentful_ResearchAreaCollection,
  Contentful_SectorCollection,
} from "graphql-types";
import { TagsContext } from "../contexts/TagsContext";
import { isDraft } from "../utils/contentful";

export enum CollectionType {
  sectors = "sectorCollection",
  researchAreas = "researchAreaCollection",
  departments = "departmentCollection",
}

type AllowedCollections =
  | Contentful_SectorCollection
  | Contentful_ResearchAreaCollection
  | Contentful_DepartmentCollection
  | null;

function cleanChildrenCollection(child: any, allowed: AllowedCollections) {
  if (!child || !child.childrenCollection || !child.childrenCollection.items || !child.childrenCollection.items.length)
    return null;

  if (!allowed) return child;

  const allowedSafe: any = allowed.items ? allowed.items : allowed;

  child.childrenCollection.items = (child as any).childrenCollection?.items.filter(
    (sector: { sys: { id: string | undefined } }) =>
      allowedSafe.find((item: { sys: { id: string | undefined } }) => item?.sys.id === sector?.sys.id)
  );

  return child;
}

function useTree(
  collectionType: CollectionType | null,
  allowed: AllowedCollections,
  restructure: boolean = true,
  sortAlphabetically: boolean = true
) {
  const TagsData: any = JSON.parse(JSON.stringify(useContext(TagsContext)));
  let tree: any = [];
  let collection: any = null;
  switch (collectionType) {
    case CollectionType.sectors:
      collection = get(TagsData, "sectorCollection");
      break;
    case CollectionType.researchAreas:
      collection = get(TagsData, "researchAreaCollection");
      break;
    case CollectionType.departments:
      collection = get(TagsData, "departmentCollection");
      break;
  }

  if (!collection || !collectionType) return [];

  let allowedItems: any = collection.items.filter((item: any) => !isDraft(item));

  if (allowed) {
    const allowedSafe = allowed.items ? allowed.items : allowed;
    // Filter out the items that are not allowed
    allowedItems = collection.items.filter((treeItem: { sys: { id: string | undefined } }) =>
      (allowedSafe as any).find((item: { sys: { id: string | undefined } }) => {
        if (!item || !item.sys || !item.sys.id) return null;
        return item?.sys.id === treeItem?.sys.id;
      })
    );

    allowedItems.map((parent: any) => {
      return cleanChildrenCollection(parent, allowed);
    });
  }

  // Create parents
  if (restructure) {
    allowedItems.forEach((item: any) => {
      if (!item) return;
      // We have a parent, add it to the tree
      if (!item.linkedFrom[collectionType]?.items.length) {
        tree.push(item);
      } else {
        // We have a child. We need to add the parent.
        item.linkedFrom[collectionType]?.items.map((child: any) => {
          const items = collection.items.find((item: any) => item?.sys.id === child?.sys.id) ?? null;
          if (items) tree.push(cleanChildrenCollection(items, allowed));
        });
      }
    });
  } else {
    tree = allowedItems;
  }

  function compare(a: any, b: any) {
    let comparison = 0;
    if (a.label > b.label) {
      comparison = 1;
    } else if (a.label < b.label) {
      comparison = -1;
    }
    return comparison;
  }

  tree = tree
    .reduce((previousValue: any, currentValue: any): [] => {
      return previousValue.includes(currentValue) ? previousValue : [...previousValue, currentValue];
    }, [])
    .filter((x: any) => x !== null);

  tree.sort(compare);

  if (sortAlphabetically) {
    tree.map((item: any) => {
      item.childrenCollection.items.sort(compare);
    });
  }

  return tree;
}

export default useTree;
