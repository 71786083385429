import { Contentful_SiteSettings } from "graphql-types";
import { useContext } from "react";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import { GroupType, OptionType } from "../components/filter";

export function ReorderFilter(groups: GroupType[]) {
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const {
    orderFilterContentTypeCollection,
    orderFilterResearchAreaCollection,
    orderFilterSectorCollection,
    orderFilterDepartmentCollection,
  } = SiteSettings;

  const contentType = "contentType";
  const researchAreas = "researchAreasCollection";
  const sectors = "sectorsCollection";
  const departments = "departments";

  const contentTypeOrdered: GroupType["items"] = [];
  const contentTypeFilter = groups.find((group) => group.ref === contentType) as Omit<GroupType, "items"> & {
    items: Array<Partial<OptionType> & { label: string }>;
  };
  if (contentTypeFilter) {
    orderFilterContentTypeCollection?.items.forEach((contentType) => {
      const item = contentTypeFilter.items.find((item) => item && contentType && item.name === contentType.name);
      if (item) contentTypeOrdered.push(item as OptionType);
    });
  }

  const researchAreasOrdered: GroupType["items"] = [];
  const researchAreasFilter = groups.find((group) => group.ref === researchAreas) as Omit<GroupType, "items"> & {
    items: Array<Partial<OptionType> & { label: string }>;
  };
  if (researchAreasFilter) {
    orderFilterResearchAreaCollection?.items.forEach((researchArea) => {
      const item = researchAreasFilter.items.find((item) => item && researchArea && item.label === researchArea.label);
      if (item) researchAreasOrdered.push(item as OptionType);
    });
  }

  const sectorsOrdered: GroupType["items"] = [];
  const sectorsFilter = groups.find((group) => group.ref === sectors) as Omit<GroupType, "items"> & {
    items: Array<Partial<OptionType> & { label: string }>;
  };
  if (sectorsFilter) {
    orderFilterSectorCollection?.items.forEach((sector) => {
      const item = sectorsFilter.items.find((item) => item && sector && item.label === sector.label);
      if (item) sectorsOrdered.push(item as OptionType);
    });
  }

  const departmentsOrdered: GroupType["items"] = [];
  const departmentsFilter = groups.find((group) => group.ref === departments) as Omit<GroupType, "items"> & {
    items: Array<Partial<OptionType> & { label: string }>;
  };
  if (departmentsFilter) {
    orderFilterDepartmentCollection?.items.forEach((department) => {
      const item = departmentsFilter.items.find((item) => item && department && item.label === department.label);
      if (item) departmentsOrdered.push(item as OptionType);
    });
  }

  const contentTypeIndex = groups.findIndex((group) => group.ref === contentType);
  const researchAreasIndex = groups.findIndex((group) => group.ref === researchAreas);
  const sectorsIndex = groups.findIndex((group) => group.ref === sectors);
  const departmentsIndex = groups.findIndex((group) => group.ref === departments);

  if (contentTypeOrdered.length > 0)
    contentTypeIndex !== -1 &&
      groups.splice(contentTypeIndex, 1, { ...groups[contentTypeIndex], items: contentTypeOrdered });
  if (researchAreasOrdered.length > 0)
    researchAreasIndex !== -1 &&
      groups.splice(researchAreasIndex, 1, { ...groups[researchAreasIndex], items: researchAreasOrdered });
  if (sectorsOrdered.length > 0)
    sectorsIndex !== -1 && groups.splice(sectorsIndex, 1, { ...groups[sectorsIndex], items: sectorsOrdered });
  if (departmentsOrdered.length > 0)
    departmentsIndex !== -1 &&
      groups.splice(departmentsIndex, 1, { ...groups[departmentsIndex], items: departmentsOrdered });

  return groups;
}
