import React, { useEffect, useState, useRef, useContext } from "react";
import SVG from "react-inlinesvg";
import gsap from "gsap";
import { Contentful_Sector, Contentful_ResearchArea, Contentful_Department } from "graphql-types";
import { GroupType } from "./index";
import { FilterContext } from "./context";
import DropdownSaveButton from "./dropdown-save-button";

function Dropdown({ group, clear, children }: { group: GroupType; clear: any; children: any }) {
  const filterContext = useContext(FilterContext);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const currentArrowDown = useRef(null);
  const currentArrowUp = useRef(null);

  // Handle click outside of nav to close dropdown
  function handleClickOutside(event: any) {
    if (isOpen && buttonRef && buttonRef.current && !buttonRef.current.contains(event.target)) setIsOpen(false);
  }

  function handleClick() {
    setIsOpen(!isOpen);
  }

  // Bind events on load
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  useEffect(() => {
    if (currentArrowDown.current && currentArrowUp.current) {
      gsap.killTweensOf([currentArrowDown.current, currentArrowUp.current]);

      if (isOpen) {
        gsap.to(currentArrowDown.current, { y: 10, opacity: 0, duration: 0.3, ease: "power3.out" });
        gsap.to(currentArrowUp.current, { y: 0, opacity: 1, duration: 0.3, ease: "power3.out" });
      } else {
        gsap.to(currentArrowDown.current, { y: 0, opacity: 1, duration: 0.3, ease: "power3.out" });
        gsap.to(currentArrowUp.current, { y: -10, opacity: 0, duration: 0.3, ease: "power3.out" });
      }
    }
    return () => {
      gsap.killTweensOf([currentArrowDown.current, currentArrowUp.current]);
    };
  }, []);

  let useSelectedItems = 0;

  filterContext &&
    (filterContext as []).forEach((g: GroupType) => {
      if (g.ref !== group.ref) return;
      g.items.map((item) => {
        if (item?.selected || item?.partial) useSelectedItems++;
        const children =
          (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];
        (children as []).map((child: any) => {
          if (child && child.selected) useSelectedItems++;
        });
      });
    });

  const label = !useSelectedItems
    ? `${group.groupLabel} `
    : `${useSelectedItems} ${group.groupLabel}${useSelectedItems > 1 ? "s" : " "}`;

  return (
    <div
      ref={buttonRef}
      className={`relative border-t border-white md:border-transparent select-none transition-colors duration-200 ${
        isOpen ? "md:text-white" : "text-slate-400"
      }`}
    >
      <button
        onClick={handleClick}
        className={`relative w-full flex items-center justify-between p-4 px-6 transition-colors duration-200 md:border md:justify-center md:rounded-full md:w-auto md:ml-1 lg:ml-2 md:shadow-filter ${
          isOpen ? "bg-slate-100 md:bg-slate-300 md:border-slate-200" : "bg-slate-100 md:bg-white"
        } ${useSelectedItems ? "md:border-slate-300" : "md:border-white"}`}
      >
        <div className="relative font-medium text-2xl text-left md:text-xs lg:text-base">{label}</div>
        <div>
          <div className="relative pl-4 md:pl-2 lg:pl-4">
            <div ref={currentArrowDown} className="relative">
              <SVG src={`/images/icons/arrow-down.svg`} width="14" height="8" title="" className="" cacheRequests={true} />
            </div>
            <div ref={currentArrowUp} className="absolute top-0 left-0 ml-4 opacity-0">
              <SVG src={`/images/icons/arrow-up.svg`} width="14" height="8" title="" className="" cacheRequests={true} />
            </div>
          </div>
        </div>
      </button>
      {group.items && isOpen && (
        <div className="relative border-b border-slate-100 md:border-transparent md:absolute md:right-0 md:pt-4 md:min-w-filter-drop-down">
          <div className="md:rounded-md md:overflow-hidden">
            {children && <ul className="overflow-auto scrolling-touch md:max-h-filter-list">{children}</ul>}
            <div className="border-t border-t-slate-400">
              <DropdownSaveButton save={handleClick} clear={() => clear(group)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
