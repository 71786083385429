import { Contentful_Sector, Contentful_ResearchArea, Contentful_Department } from "graphql-types";
import { GroupType, OptionType } from "../filter";

export function getFilters(key: string, filters: any) {
  return (
    [...filters]
      .map((item): any => {
        if (item.type === key) return `${key.replace("Collection", "")}.sys.id:${item.id}`;
        return null;
      })
      .filter((x: any) => x !== null) ?? []
  );
}

export function algoliaFilters(data: GroupType[]) {
  let filters: any = [];

  data.map((group) => {
    group.items.map((item) => {
      if (item?.selected || item?.partial)
        filters.push({
          type: group.ref,
          id: item?.sys.id,
        });

      const children =
        (item as Contentful_Sector | Contentful_ResearchArea | Contentful_Department).childrenCollection?.items ?? [];

      (children as OptionType[]).map((child) => {
        if (child.selected)
          filters.push({
            type: group.ref,
            id: child.sys.id,
          });
      });
    });
  });

  return filters;
}
