import React from "react";
import { getSizeClassName } from "./tile/index";
import { TileSize } from "./tile/types";

function BlankTile({ style, size }: { style?: any; size: TileSize }) {
  const sizeClassName = getSizeClassName(size);

  return (
    <div
      className="font-medium leading-tight pb-4 hover:opacity-80 transition-opacity duration-700 animate-pulse"
      style={style}
    >
      <div className="pb-4">
        <div className={`overflow-hidden rounded-3xl bg-gray-300 relative ${sizeClassName}`}></div>
      </div>
      {size !== "featured" && (
        <div className="pb-4">
          <h2 className="text-lg mb-2 inline-block w-1/2 bg-gray-300">&nbsp;</h2>
        </div>
      )}
    </div>
  );
}

BlankTile.defaultProps = {
  size: "tile",
};

// export default BlankTile;
export default React.memo(BlankTile);
