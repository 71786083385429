import React from "react";
import BlankTile from "../blank-tile";
import { TileSize } from "../tile/types";

function Loading({ loading, max = 8 }: { loading: boolean; max?: number }) {
  if (!loading) return null;
  return (
    <div className="md:grid md:grid-cols-4 md:gap-6">
      {[...Array(max)].map((x, i) => (
        <BlankTile
          key={i}
          size={TileSize.Square}
          style={{
            animationFillMode: "backwards",
            animationDelay: `${150 * i}ms`,
          }}
        />
      ))}
    </div>
  );
}

export default Loading;
